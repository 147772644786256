<template>
  <div>
    <table v-if="!loading && routers" class="table table-striped table-hover">
      <tbody>
        <tr>
          <th class="d-flex justify-content-center">
            <input
              type="checkbox"
              class="form-check-input"
              @change="handleSelectAllRouters($event.target.checked)"
            />
          </th>
          <th>
            Router Serial
          </th>
        </tr>
        <tr v-for="router in routers" :key="router.id">
          <td class="d-flex justify-content-center">
            <input
              :checked="selectedRouters.includes(router.id)"
              type="checkbox"
              class="form-check-input"
              @change="handleSelectRouter($event.target.checked, router.id)"
            />
          </td>
          <td>{{ router.serial_number }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>

    <div class="text-center pt-3">
      <CustomPaginator
        v-if="totalRouters > 100"
        :basePaginatorUrl="basePaginatorUrl"
        :totalRecords="totalRouters"
        @fetch-next-page-results="getRouters"
      />
    </div>
    <div class="d-flex justify-content-center py-4">
      <button
        class="btn btn-outline-secondary mr-4"
        type="button"
        data-dismiss="modal"
      >
        Close
      </button>
      <button
        class="btn btn-primary"
        :disabled="selectedRouters.length === 0 || loading"
        @click="assignRouters"
      >
        Assign Selected Routers ({{ selectedRouters.length }})
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CustomPaginator from "../../BaseComponents/CustomPaginator.vue";

export default {
  name: "PartnerRouterAssignTable",
  components: { CustomPaginator },
  data() {
    return {
      router: "",
      routers: null,
      selectedRouters: [],
      responseErrors: {},
      loading: false,
      partner: "",
      totalRouters: null,
      basePaginatorUrl: null,
    };
  },
  mounted() {
    this.getRouters();
    this.getPartner(this.$route.params.id);
  },
  methods: {
    assignRouters() {
      this.loading = true;
      const data = {
        partner: this.$route.params.id,
        routers: this.selectedRouters.map((router) => ({ id: router })),
      };

      axios
        .post(`v1/routers/assign-to-partner/`, data)
        .then((response) => {
          if (response.status === 200) {
            this.$swal("Success", `${response.data.detail}`, "success");
            this.loading = false;
            this.$forceUpdate();
            window.location.reload(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.responseErrors = error.response.data;
            this.loading = false;
          }
        });
    },
    getRouters(
      clearRouters,
      routerUrl = "v1/routers/?has_been_assigned_partner=false&has_kiosk=false&partner=&location=Delivered"
    ) {
      this.basePaginatorUrl = routerUrl;
      if (clearRouters) {
        this.routers = [];
      }
      axios
        .get(routerUrl)
        .then((response) => {
          if (response.status === 200) {
            this.totalRouters = response.data.count;
            this.routers = response.data.results;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    getPartner(id) {
      axios
        .get(`v1/partners/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.partner = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    handleSelectRouter(selected, id) {
      const currentlySelected = new Set(this.selectedRouters);
      if (selected) {
        currentlySelected.add(id);
        this.selectedRouters = Array.from(currentlySelected);
      } else {
        this.selectedRouters = this.selectedRouters.filter(
          (routerId) => routerId !== id
        );
      }
    },
    handleSelectAllRouters(selected) {
      const currentlySelected = new Set(
        this.routers.map((router) => router.id)
      );
      if (selected) {
        this.selectedRouters = Array.from(currentlySelected);
      } else {
        this.selectedRouters = [];
      }
    },
  },
};
</script>

<style></style>
