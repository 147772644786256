<template>
  <div class="overflow-auto">
    <b-pagination-nav
      v-model="currentPage"
      :base-url="basePaginatorUrl"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :number-of-pages="numOfPages"
      align="center"
      first-number
      last-number
      @change="pageClick"
    ></b-pagination-nav>
  </div>
</template>

<script>
export default {
  name: "CustomPaginator",
  props: {
    perPage: {
      type: Number,
      default() {
        return 100;
      },
    },
    basePaginatorUrl: String,
    totalRecords: Number,
  },
  computed: {
    rows() {
      return this.totalRecords;
    },
    numOfPages() {
      var pageCalcutor = Math.ceil(this.rows / this.perPage);
      return pageCalcutor;
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    linkGen(pageNum) {
      return {
        link: pageNum === null,
      };
    },
    pageClick(page) {
      this.currentPage = page;
      this.$emit(
        "fetch-next-page-results",
        "true",
        `${this.basePaginatorUrl}&page=${page}`
      );
    },
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: var(--black);
  background-color: #fff !important;
  border-color: var(--ared-green) !important;
}
.page-link {
  color: var(--ared-green) !important;
}
.page-item .active {
  color: inherit;
}
</style>
