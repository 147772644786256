var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mx-auto"},[_c('div',{staticClass:"clearfix mb-4"},[_c('h2',{staticClass:"text-uppercase float-left mb-0"},[_vm._v(_vm._s(_vm.activeTab)+" routers")])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-4"},[_c('div',{class:[
          `card text-center`,
          _vm.activeTab === 'functional' ? 'bg-primary text-white' : '',
        ],staticStyle:{"cursor":"pointer"},on:{"click":() => _vm.handleSetActiveTab('functional')}},[_c('div',{staticClass:"p-2"},[_vm._v(" Functional ")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{class:[
          `card text-center`,
          _vm.activeTab === 'damaged' ? 'bg-primary text-white' : '',
        ],staticStyle:{"cursor":"pointer"},on:{"click":() => _vm.handleSetActiveTab('damaged')}},[_c('div',{staticClass:"p-2"},[_vm._v(" Damaged ")])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{class:[
          `card text-center`,
          _vm.activeTab === 'all' ? 'bg-primary text-white' : '',
        ],staticStyle:{"cursor":"pointer"},on:{"click":() => _vm.handleSetActiveTab('all')}},[_c('div',{staticClass:"p-2"},[_vm._v(" All ")])])])]),_c('DataTable',{attrs:{"columns":[
      { field: 'serial_number', label: 'Serial Number' },
      {
        field: 'active',
        label: 'Status',
        formatFn: _vm.statusFormatter,
      },
      {
        field: 'last_contact',
        label: 'Last Contact',
        formatFn: _vm.dateTimeFormatter,
      },
      { field: 'created', label: 'Added', formatFn: _vm.dateTimeFormatter },
      { field: 'view', label: 'Action', sortable: false },
    ],"rows":_vm.filteredRouters,"activeTab":_vm.activeTab,"basePaginatorUrl":_vm.basePaginatorUrl,"totalRecords":_vm.totalRouters,"detailsRouteName":"router-details"},on:{"get-selected-rows":_vm.getSelectedRows,"get-routers":_vm.getRouters,"fetch-next-page-results":_vm.getRouters}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }