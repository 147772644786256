<template>
  <div class="card card-table">
    <div class="card-header">
      All SMEs
    </div>
    <table class="table table-striped table-hover table-fw-widget" id="table5">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>Country</th>
          <th>Added</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="business in businesses"
          :key="business.id"
          class="odd gradeX"
        >
          <td>{{ business.name }}</td>
          <td>{{ business.email }}</td>
          <td>{{ business.phone }}</td>
          <td>{{ business.country }}</td>
          <td>
            {{ new Date(business.created).toLocaleString() }}
          </td>
          <td>
            <!-- <business-link
              :to="{ name: 'business-details', params: { id: business.id } }"
            >
              <button class="btn btn-primary">View</button>
            </business-link> -->
          </td>
          <td class="center"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PartnerSmeTable",
  data() {
    return {
      businesses: "",
      error: "",
    };
  },
  mounted() {
    this.getSme(this.$route.params.id);
  },
  methods: {
    getSme(id) {
      axios
        .get(`v1/smes/?partner=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.businesses = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          } else if (error.response.status == 500) {
            this.$swal(
              "Internal Server Error",
              "Internal Server Error",
              "info"
            );
          }
        });
    },
  },
};
</script>
