<template>
  <div>
    <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-success">
          <div class="card-header card-header-divider">
            Assign Kiosk
            <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
            >
              Cancel
            </div>
          </div>
          <div class="card-body">
            <PartnerKioskAssignTable />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isHidden" class="row">
      <div class="col-sm-12">
        <div class="card card-table">
          <div class="card-body">
            <PartnerKiosksTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PartnerKioskAssignTable from "@/components/Partners/Kiosks/AssignTable";
import PartnerKiosksTable from "@/components/Partners/Kiosks/Table";

export default {
  name: "PartnersKioskComponent",
  components: { PartnerKioskAssignTable, PartnerKiosksTable },
  data() {
    return {
      isHidden: false,
    };
  },
};
</script>
<style></style>
