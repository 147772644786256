<template>
  <div class="col-12 mx-auto">
    <div class="clearfix mb-4">
      <h2 class="text-uppercase float-left mb-0">{{ activeTab }} routers</h2>
    </div>
    <div class="row mb-4">
      <div class="col-md-4">
        <div
          :class="[
            `card text-center`,
            activeTab === 'functional' ? 'bg-primary text-white' : '',
          ]"
          @click="() => handleSetActiveTab('functional')"
          style="cursor: pointer"
        >
          <div class="p-2">
            Functional
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          :class="[
            `card text-center`,
            activeTab === 'damaged' ? 'bg-primary text-white' : '',
          ]"
          @click="() => handleSetActiveTab('damaged')"
          style="cursor: pointer"
        >
          <div class="p-2">
            Damaged
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          :class="[
            `card text-center`,
            activeTab === 'all' ? 'bg-primary text-white' : '',
          ]"
          @click="() => handleSetActiveTab('all')"
          style="cursor: pointer"
        >
          <div class="p-2">
            All
          </div>
        </div>
      </div>
    </div>
    <DataTable
      :columns="[
        { field: 'serial_number', label: 'Serial Number' },
        {
          field: 'active',
          label: 'Status',
          formatFn: statusFormatter,
        },
        {
          field: 'last_contact',
          label: 'Last Contact',
          formatFn: dateTimeFormatter,
        },
        { field: 'created', label: 'Added', formatFn: dateTimeFormatter },
        { field: 'view', label: 'Action', sortable: false },
      ]"
      :rows="filteredRouters"
      :activeTab="activeTab"
      :basePaginatorUrl="basePaginatorUrl"
      :totalRecords="totalRouters"
      detailsRouteName="router-details"
      @get-selected-rows="getSelectedRows"
      @get-routers="getRouters"
      @fetch-next-page-results="getRouters"
    />
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "@/components/DataTable";

export default {
  name: "PartnerRoutersTable",
  components: { DataTable },
  data() {
    return {
      activeTab: "functional",
      allRouters: [],
      totalRouters: null,
      basePaginatorUrl: null,
      filteredRouters: [],
      filtered: false,
      error: "",
      selectedRouters: null,
    };
  },
  mounted() {
    this.getRouters();
  },
  methods: {
    getSelectedRows(records) {
      this.selectedRouters = records;
    },
    getRouters(
      clearRouters,
      routerUrl = `v1/routers/?partner=${this.$route.params.id}`
    ) {
      if (clearRouters) {
        this.filteredRouters = [];
      }
      this.basePaginatorUrl = routerUrl;
      axios
        .get(routerUrl)
        .then((response) => {
          if (response.status === 200) {
            this.totalRouters = response.data.count;
            this.allRouters = response.data.results;
            this.filteredRouters = this.allRouters;
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            this.$swal("Internal Server", "Internal Server Error", "info");
          } else if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    dateTimeFormatter(value) {
      return new Date(value).toLocaleString();
    },
    statusFormatter(value) {
      if (value === 1) {
        return "";
      } else {
        return "";
      }
    },
    handleSetActiveTab(tab) {
      this.activeTab = tab;
      if (tab === "all") {
        this.getRouters(true);
      } else if (tab === "functional") {
        this.getRouters(
          true,
          `v1/routers/?partner=${this.$route.params.id}&active=true`
        );
        this.filtered = true;
      } else if (tab === "damaged") {
        this.getRouters(
          true,
          `v1/routers/?partner=${this.$route.params.id}&active=false`
        );
      }
    },
    toggleIsHidden() {
      this.showPartnersSelect = !this.showPartnersSelect;
    },
  },
};
</script>
