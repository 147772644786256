<template>
  <div>
    <table class="table table-striped table-hover">
      <tbody>
        <tr>
          <th class="d-flex justify-content-center">
            <input
              type="checkbox"
              class="form-check-input"
              @change="handleSelectAllKiosks($event.target.checked)"
            />
          </th>
          <th>
            Kiosk Serial
          </th>
        </tr>
        <tr v-for="kiosk in kiosks" :key="kiosk.id">
          <td class="d-flex justify-content-center">
            <input
              :checked="selectedKiosks.includes(kiosk.id)"
              type="checkbox"
              class="form-check-input"
              @change="handleSelectKiosk($event.target.checked, kiosk.id)"
            />
          </td>
          <td>{{ kiosk.serial_number }}</td>
        </tr>
        <tr v-if="kiosks.length === 0">
          <td colspan="2">
            No unassigned kiosks found.
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center pt-3">
      <CustomPaginator
        v-if="totalKiosks > 100"
        :basePaginatorUrl="basePaginatorUrl"
        :totalRecords="totalKiosks"
        @fetch-next-page-results="getkiosks"
      />
    </div>
    <div class="d-flex justify-content-center py-4">
      <button
        class="btn btn-outline-secondary mr-4"
        type="button"
        data-dismiss="modal"
      >
        Close
      </button>
      <button
        class="btn btn-primary"
        :disabled="selectedKiosks.length === 0 || loading"
        @click="assignKiosks"
      >
        Assign Selected Kiosks ({{ selectedKiosks.length }})
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CustomPaginator from "../../BaseComponents/CustomPaginator.vue";
export default {
  name: "PartnerKioskAssignTable",
  components: { CustomPaginator },
  data() {
    return {
      kiosk: "",
      kiosks: "",
      totalKiosks: null,
      basePaginationUrl: null,
      selectedKiosks: [],
      responseErrors: {},
      loading: false,
      partner: "",
    };
  },
  mounted() {
    this.getkiosks();
  },
  methods: {
    assignKiosks() {
      this.loading = true;
      const data = {
        partner: this.$route.params.id,
        kiosks: this.selectedKiosks.map((kiosk) => ({ id: kiosk })),
      };

      axios
        .post(`v1/kiosks/assign-to-partner/`, data)
        .then((response) => {
          if (response.status === 200) {
            this.$swal("Success", `${response.data.detail}`, "success");
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.responseErrors = error.response.data;
            this.loading = false;
          }
        });
    },
    getkiosks(
      clearKiosks,
      kioskUrl = "v1/kiosks/?location=Delivered&is_assigned=false&functional=true"
    ) {
      this.basePaginatorUrl = kioskUrl;
      if (clearKiosks) {
        this.clearKiosks = [];
      }
      axios
        .get(kioskUrl)
        .then((response) => {
          if (response.status === 200) {
            this.totalKiosks = response.data.count;
            this.kiosks = response.data.results;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    handleSelectKiosk(selected, id) {
      const currentlySelected = new Set(this.selectedKiosks);
      if (selected) {
        currentlySelected.add(id);
        this.selectedKiosks = Array.from(currentlySelected);
      } else {
        this.selectedKiosks = this.selectedKiosks.filter(
          (kioskId) => kioskId !== id
        );
      }
    },
    handleSelectAllKiosks(selected) {
      const currentlySelected = new Set(this.kiosks.map((kiosk) => kiosk.id));
      if (selected) {
        this.selectedKiosks = Array.from(currentlySelected);
      } else {
        this.selectedKiosks = [];
      }
    },
  },
};
</script>

<style></style>
