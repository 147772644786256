<template>
  <div>
    <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-success">
          <div class="card-header card-header-divider">
            Assign Router
            <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
            >
              Cancel
            </div>
          </div>
          <div class="card-body">
            <PartnerRouterAssignForm />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isHidden" class="row">
      <div class="col-sm-12">
        <div class="card card-table">
          <div class="card-body py-3">
            <PartnerRoutersTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PartnerRoutersTable from "@/components/Partners/Routers/Table";

export default {
  name: "PartnerRouterComponent",
  components: { PartnerRoutersTable },
  data() {
    return {
      isHidden: false,
    };
  },
};
</script>
<style></style>
