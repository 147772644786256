<template>
  <form method="post" @submit.prevent="updatePartner">
    <div v-if="isHidden" class="col-12">
      <div class="form-group pt-2">
        <label for="inputEmail">Company Name</label>
        <input
          v-model="partner.name"
          class="form-control"
          id="inputName"
          type="text"
          placeholder="Enter Company Name"
          required
        />
        <small v-if="responseErrors.name" class="text-danger">
          {{ responseErrors.name[0] }}
        </small>
      </div>
      <div class="form-group pt-2">
        <label for="selectCountry">Country</label>
        <select
          v-model="partner.country"
          class="form-control"
          id="country"
          name="country"
          required
        >
          <option value="">Select Country</option>
          <option value="Algeria">Algeria</option>
          <option value="Angola">Angola</option>
          <option value="Benin">Benin</option>
          <option value="Botswana">Botswana</option>
          <option value="Burkina Faso">Burkina Faso</option>
          <option value="Burundi">Burundi</option>
          <option value="Cabo Verde">Cabo Verde</option>
          <option value="Cameroon">Cameroon</option>
          <option value="Central African Republic"
            >Central African Republic (CAR)</option
          >
          <option value="Chad">Chad</option>
          <option value="Comoros">Comoros</option>
          <option value="Congo">Congo</option>
          <option value="Congo">Congo</option>
          <option value="Cote d'Ivoire">Cote d'Ivoire</option>
          <option value="Djibouti">Djibouti</option>
          <option value="Egypt">Egypt</option>
          <option value="Equatorial Guinea">Equatorial Guinea</option>
          <option value="Eritrea">Eritrea</option>
          <option value="Eswatini">Eswatini (formerly Swaziland)</option>
          <option value="Ethiopia">Ethiopia</option>
          <option value="Gabon">Gabon</option>
          <option value="Gambia">Gambia</option>
          <option value="Ghana">Ghana</option>
          <option value="Guinea">Guinea</option>
          <option value="Guinea-Bissau">Guinea-Bissau</option>
          <option value="Kenya">Kenya</option>
          <option value="Lesotho">Lesotho</option>
          <option value="Liberia">Liberia</option>
          <option value="Libya">Libya</option>
          <option value="Madagascar">Madagascar</option>
          <option value="Malawi">Malawi</option>
          <option value="Mali">Mali</option>
          <option value="Mauritania">Mauritania</option>
          <option value="Mauritius">Mauritius</option>
          <option value="Morocco">Morocco</option>
          <option value="Mozambique">Mozambique</option>
          <option value="Namibia">Namibia</option>
          <option value="Niger">Niger</option>
          <option value="Nigeria">Nigeria</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Sao Tome and Principe">Sao Tome and Principe</option>
          <option value="Senegal">Senegal</option>
          <option value="Seychelles">Seychelles</option>
          <option value="Sierra Leone">Sierra Leone</option>
          <option value="Somalia">Somalia</option>
          <option value="South Africa">South Africa</option>
          <option value="South Sudan">South Sudan</option>
          <option value="Sudan">Sudan</option>
          <option value="Tanzania">Tanzania</option>
          <option value="Togo">Togo</option>
          <option value="Tunisia">Tunisia</option>
          <option value="Uganda">Uganda</option>
          <option value="Zambia">Zambia</option>
          <option value="Zimbabwe">Zimbabwe</option>
        </select>
      </div>
      <div class="form-group pt-2">
        <label for="selectPlan">Plan</label>
        <select
          v-model="partner.plan.id"
          class="form-control"
          id="plan"
          name="plan"
          required
        >
          <option value="">Select Plan</option>
          <option v-for="plan in plans" :key="plan.id" :value="plan.id">
            {{ plan.name | planNameDisplay }}</option
          >
        </select>
        <small v-if="responseErrors.plan" class="text-danger">
          {{ responseErrors.plan[0] }}
        </small>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group pt-2">
        <label for="inputEmail">Company Admin Name</label>
        <input
          v-model="partner.admin_name"
          class="form-control"
          id="inputAdminFirstName"
          type="text"
          placeholder="Enter Company Admin Name"
          required
        />
        <small v-if="responseErrors.admin_name" class="text-danger">
          {{ responseErrors.admin_name[0] }}
        </small>
      </div>
      <div class="form-group pt-2">
        <label for="inputEmail">Company Admin Email Address</label>
        <input
          v-model="partner.admin_email"
          class="form-control"
          id="inputAdminEmail"
          type="email"
          placeholder="Enter Company Admin Email"
          required
        />
        <small v-if="responseErrors.admin_email" class="text-danger">
          {{ responseErrors.admin_email[0] }}
        </small>
      </div>
      <div class="form-group pt-2">
        <label for="inputPhoneNumber">Admin Phone Number</label>
        <vue-tel-input
          v-model="partner.admin_phone"
          placeholder="Company Phone Number"
          v-bind="bindProps"
          required
        ></vue-tel-input>
        <small v-if="responseErrors.admin_phone" class="text-danger">
          {{ responseErrors.admin_phone[0] }}
        </small>
      </div>
      <div class="d-flex justify-content-center pt-3">
        <button
          class="btn btn-space btn-primary"
          :disabled="submitting"
          @click="$emit('hide-form')"
        >
          cancel
        </button>
        <button
          class="btn btn-space btn-primary"
          :disabled="submitting"
          type="submit"
        >
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm"
          ></span>
          {{ submit }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
export default {
  name: "PartnerCreationForm",
  filters: {
    planNameDisplay: function(value) {
      if (value == "SME") {
        return "Router Management";
      } else if (value == "Kiosk") {
        return "Kiosk Management";
      } else if (value == "Premium") {
        return "Premium Plan";
      }
    },
  },
  data() {
    return {
      submitting: false,
      submit: "Update Partner",
      error: "",
      responseErrors: {},
      plans: "",
      isHidden: true,
      bindProps: {
        mode: "international",
      },
    };
  },
  props: {
    partner: {
      type: Object,
      default() {
        return  {
        name: "",
        email: "",
        country: "",
        plan: { id: "", name: "" },
        admin_name: "",
        admin_email: "",
        }
      }
    }
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    async updatePartner() {
      this.submitting = true;
      this.submit = "Updating Partner";
      this.partner.plan = this.partner.plan.id

      await axios
        .patch(`v1/partners/${this.$route.params.id}/`, this.partner)
        .then((response) => {
          if (response.status === 200) {
            this.$emit('get-partner', this.$route.params.id);
            this.$swal("Success", "Partner Updated Successfully", "success");
            this.$emit('hide-form');
          }
        })
        .catch((error) => {
          if (error.response) {
            this.responseErrors = error.response.data;
            this.submitting = false;
            this.submit = "Update Partner";
            // this.$swal(`${error.response.data}`, "error");
          }
        });
    },
    async getPlans() {
      await axios
        .get(`v1/plans/`)
        .then((response) => {
          if (response.status === 200) {
            this.plans = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal(`${error.response.data}`, "error");
          }
        });
    },
  },
};
</script>
