<template>
  <div>
    <div v-if="!formVisible" class="container-fluid pb-3 pt-5 pt-md-6">
      <div class="row col-12 page-head">
        <nav aria-label="breadcrumb" role="navigation">
          <ol class="breadcrumb page-head-nav">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard' }">Home</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'partners' }">Partners</router-link>
            </li>
            <li class="breadcrumb-item">Partners Details</li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="user-profile col-md-4 mb-3">
          <div class="user-display">
            <div class="user-display-bg">
              <img src="/img/deal.png" alt="Profile Background" />
            </div>
            <div class="row mx-3">
              <div class="col-12">
                <h4 class="font-weight-bold">{{ partner.name }}</h4>
                <p class="text-muted">
                  <span class="mdi mdi-case"></span>
                  {{ partner.plan.name | planNameDisplay }}
                </p>
              </div>
            </div>
            <div class="user-display-bottom">
              <div class="row text-center user-display-details">
                <div class="col-3">
                  <div class="title">Admins</div>
                  <div class="counter">0</div>
                </div>
                <div
                  v-if="
                    partner.plan.name == 'Premium' || partner.plan.name == 'SME'
                  "
                  class="col-3"
                >
                  <div class="title">Routers</div>
                  <div class="counter">{{ routerCount }}</div>
                </div>
                <div
                  v-if="
                    partner.plan.name == 'Premium' || partner.plan.name == 'SME'
                  "
                  class="col-3"
                >
                  <div class="title">SMEs</div>
                  <div class="counter">{{ smeCount }}</div>
                </div>
                <div
                  v-if="
                    partner.plan.name == 'Kiosk' ||
                    partner.plan.name == 'Premium'
                  "
                  class="col-3"
                >
                  <div class="title">Kiosks</div>
                  <div class="counter">{{ kioskCount }}</div>
                </div>
              </div>
            </div>
            <hr />
            <div
              class="d-flex justify-content-around align-items-center pb-4 pt-1 px-3"
              v-if="loggedInUser.role === adminRole"
            >
              <div class="">
                <b-button
                  v-if="partner.is_active"
                  variant="danger"
                  @click="toggleSuspendModal"
                  >Suspend Partner</b-button
                >
                <b-button v-else variant="success" @click="toggleSuspendModal"
                  >Activate Partner</b-button
                >
              </div>
              <div class="">
                <b-button @click="toggleFormVisible" variant="success"
                  >Update Partner</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 mb-3">
          <div class="card shadow-sm">
            <div class="card-body pb-5">
              <div class="scroll">
                <h3 class="text-uppercase mb-4">Full profile</h3>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Name</td>
                      <td>{{ partner.name }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Country</td>
                      <td>{{ partner.country }}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <span v-if="partner.is_active" class="text-success"
                          >Active</span
                        >
                        <span v-else class="text-warning">Inactive</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Admin</td>
                      <td>{{ partner.admin_name }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Admin Email</td>
                      <td>{{ partner.admin_email }}</td>
                    </tr>
                    <!-- <tr>
                    <th>Status</th>
                    <td>
                      <span v-if="partner.active" class="text-success"
                        >Active</span
                      >
                      <span v-else class="text-danger">Deactivated</span>
                    </td>
                  </tr> -->

                    <tr>
                      <td class="font-weight-bold">Created</td>
                      <td>{{ new Date(partner.created).toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="d-flex justify-content-around py-3"
                v-if="loggedInUser.role === adminRole"
              >
                <button
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#assign-router-modal"
                >
                  Assign Routers
                </button>
                <button
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#assign-kiosk-modal"
                >
                  Assign Kiosks
                </button>
              </div>
            </div>
          </div>
        </div>
        <DeleteEntity
          :action="suspendModalState.accountAction"
          :modalMessage="suspendModalState.suspendModalMessage"
        />
        <div class="col-md-12 mb-3">
          <nav class="nav nav-pills nav-fill mb-3">
            <a
              v-if="
                partner.plan.name == 'Premium' || partner.plan.name == 'SME'
              "
              :class="[
                'nav-item',
                'nav-link',
                'mr-1',
                'btn',
                { active: activeTab === 'routers' },
              ]"
              v-on:click="setActiveTab('routers')"
            >
              Routers
            </a>
            <a
              v-if="
                partner.plan.name == 'Premium' || partner.plan.name == 'Kiosk'
              "
              :class="[
                'nav-item',
                'nav-link',
                'mr-1',
                'btn',
                { active: activeTab === 'kiosks' },
              ]"
              v-on:click="setActiveTab('kiosks')"
            >
              Kiosks
            </a>
            <a
              v-if="
                partner.plan.name == 'Premium' || partner.plan.name == 'SME'
              "
              :class="[
                'nav-item',
                'nav-link',
                'mr-1',
                'btn',
                { active: activeTab === 'businesses' },
              ]"
              v-on:click="setActiveTab('businesses')"
            >
              List of SME's
            </a>
          </nav>
          <div class="card shadow-sm">
            <div class="card-body">
              <div v-if="activeTab === 'routers'">
                <PartnerRouterComponent />
              </div>

              <div v-if="activeTab === 'businesses'">
                <PartnerSmeTable />
              </div>
              <div v-if="activeTab === 'kiosks'">
                <PartnersKioskComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpdatePartnerForm
      v-else
      @hide-form="toggleFormVisible"
      @get-partner="getPartner"
      :partner="partner"
    />

    <div class="modal fade" id="assign-router-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Assign Routers</h3>
          </div>
          <div class="modal-body">
            <PartnerRouterAssignTable />
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="assign-kiosk-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Assign Kiosks</h3>
          </div>
          <div class="modal-body">
            <PartnerKioskAssignTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import PartnerRouterComponent from "@/components/Partners/Routers/RoutersComponent";
import PartnerSmeTable from "@/components/Partners/SME/Table";
import PartnersKioskComponent from "@/components/Partners/Kiosks/KioskComponent";
import DeleteEntity from "@/components/DeleteEntity";
import UpdatePartnerForm from "../../components/Partners/UpdatePartnerForm.vue";
import PartnerRouterAssignTable from "@/components/Partners/Routers/AssignTable";
import PartnerKioskAssignTable from "@/components/Partners/Kiosks/AssignTable";

export default {
  components: {
    DeleteEntity,
    PartnersKioskComponent,
    PartnerRouterComponent,
    PartnerSmeTable,
    UpdatePartnerForm,
    PartnerRouterAssignTable,
    PartnerKioskAssignTable,
  },
  filters: {
    planNameDisplay: function (value) {
      if (value === "SME") {
        return "Router Management";
      } else if (value === "Kiosk") {
        return "Kiosk Management";
      } else if (value === "Premium") {
        return "Premium Plan";
      }
    },
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
    suspendModalState() {
      let accountAction;
      let suspendModalMessage;
      if (this.partner.is_active) {
        accountAction = "suspend";
        suspendModalMessage = `Are you sure you want to suspend ${this.partner.name}?`;
      } else {
        accountAction = "activate";
        suspendModalMessage = `Are you sure you want to activate ${this.partner.name}?`;
      }
      const data = {
        suspendModalMessage,
        accountAction,
      };
      return data;
    },
  },
  data() {
    return {
      formVisible: false,
      partner: {},
      activeTab: "routers",
      kioskCount: "",
      smeCount: "",
      routerCount: "",
      adminRole: "ared_admin",
    };
  },
  mounted() {
    this.getPartner(this.$route.params.id);
    this.getkiosksCount(this.$route.params.id);
    this.getSmeCount(this.$route.params.id);
    this.getRouterCount(this.$route.params.id);
  },
  methods: {
    toggleFormVisible() {
      this.formVisible = !this.formVisible;
    },
    getPartner(id) {
      axios
        .get(`v1/partners/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.partner = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    getkiosksCount(id) {
      axios
        .get(`v1/kiosks/?partner=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.kioskCount = response.data.count;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    getSmeCount(id) {
      axios
        .get(`v1/smes/?partner=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.smeCount = response.data.length;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    getRouterCount(id) {
      axios
        .get(`v1/routers/?partner=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.routerCount = response.data.count;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    setActiveTab(tab) {
      if (this.partner.plan.name === "Kiosk") {
        this.activeTab = "kiosks";
      } else {
        this.activeTab = tab;
      }
    },
    toggleSuspendModal() {
      this.$root.$emit("bv::show::modal", "delete-modal");
    },
  },
};
</script>

<style>
.active {
  color: rgb(255, 255, 255) !important;
}
</style>
