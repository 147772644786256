<template>
  <div class="col-12 mx-auto">
    <div class="clearfix mb-4">
      <h2 class="text-uppercase float-left mb-0">{{ activeTab }} Kiosks</h2>
    </div>
    <div class="row mb-4">
      <div class="col-md-4">
        <div
          :class="[
            `card text-center`,
            activeTab === 'functional' ? 'bg-primary text-white' : '',
          ]"
          @click="() => handleSetActiveTab('functional')"
          style="cursor: pointer"
        >
          <div class="p-2">
            Functional
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          :class="[
            `card text-center`,
            activeTab === 'damaged' ? 'bg-primary text-white' : '',
          ]"
          @click="() => handleSetActiveTab('damaged')"
          style="cursor: pointer"
        >
          <div class="p-2">
            Damaged
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div
          :class="[
            `card text-center`,
            activeTab === 'all' ? 'bg-primary text-white' : '',
          ]"
          @click="() => handleSetActiveTab('all')"
          style="cursor: pointer"
        >
          <div class="p-2">
            All
          </div>
        </div>
      </div>
    </div>
    <DataTable
      :columns="[
        { field: 'serial_number', label: 'Serial Number' },
        {
          field: 'functional',
          label: 'Status',
          formatFn: isFunctionalFormatter,
        },
        { field: 'created', label: 'Added', formatFn: createdFormatter },
        { field: 'view', label: 'Action', sortable: false },
      ]"
      :rows="filteredKiosks"
      :totalRecords="totalKiosks"
      :basePaginatorUrl="baseKioskPaginatorUrl"
      detailsRouteName="kiosk-details"
      @fetch-next-page-results="fetchPartnerKiosks"
    />
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "@/components/DataTable";

export default {
  components: { DataTable },
  name: "PartnerKiosksTable",
  data() {
    return {
      activeTab: "functional",
      allKiosks: null,
      filteredKiosks: null,
      filtered: false,
      error: "",
      totalKiosks: null,
      baseKioskPaginatorUrl: null,
    };
  },
  mounted() {
    this.fetchPartnerKiosks();
  },
  methods: {
    fetchPartnerKiosks(
      clearKiosks,
      kioskUrl = `v1/kiosks/?partner=${this.$route.params.id}`
    ) {
      this.baseKioskPaginatorUrl = kioskUrl;
      if (clearKiosks) {
        this.filteredKiosks = [];
      }
      axios
        .get(kioskUrl)
        .then((response) => {
          if (response.status === 200) {
            this.totalKiosks = response.data.count;
            this.allKiosks = response.data.results;
            this.filteredKiosks = this.allKiosks;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    handleSetActiveTab(tab) {
      this.activeTab = tab;
      if (tab === "all") {
        this.fetchPartnerKiosks(true);
      } else if (tab === "functional") {
        const kioskUrl = `v1/kiosks/?partner=${this.$route.params.id}&functional=true`;
        this.fetchPartnerKiosks(true, kioskUrl);
      } else if (tab === "damaged") {
        const kioskUrl = `v1/kiosks/?partner=${this.$route.params.id}&functional=false`;
        this.fetchPartnerKiosks(true, kioskUrl);
      }
    },
    createdFormatter(value) {
      return new Date(value).toLocaleString();
    },
    isFunctionalFormatter(value) {
      return value ? "" : "";
    },
  },
};
</script>
